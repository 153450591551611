<script>
import ListItemTemplate from './list-item-template.vue'

export default {
  name: 'DossierDefinitionListItem',
  components: { ListItemTemplate },
  props: {
    ...ListItemTemplate.props,
    showCounts: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<template>
  <list-item-template
    skeleton-type="list-item-avatar-two-line"
    v-bind="$props"
    @click="$emit('click', value)"
  >
    <v-list-item-content>
      <v-list-item-title>{{ value.name }}</v-list-item-title>
      <v-list-item-subtitle>{{ value.descriptionText }}</v-list-item-subtitle>
      <v-list-item-subtitle
        v-if="showCounts"
        class="custom-meta-list mt-1"
        :class="{'error--text': value.groupCount === 0}"
      >
        <span class="d-flex">{{ $tc('general.counts.instances', value.instanceCount) }}</span>
        <span class="d-flex">{{ $tc('general.counts.groups', value.groupCount) }}</span>
      </v-list-item-subtitle>
    </v-list-item-content>
  </list-item-template>
</template>
