<script>
import { PageContentable } from '../page'
import Breadcrumbs from 'components/breadcrumbs.vue'
import PageDetailHeader from 'components/page-detail-header.vue'
import KebabMenu from 'components/kebab-menu.vue'
import CustomDialog from 'dialogs/custom-dialog.vue'
import Dossier from 'mixins/models/dossier'
import Request from 'api/request'
import { routeFor } from 'helpers/route'

export default {
  name: 'DossierEditHeader',
  components: {
    Breadcrumbs,
    PageDetailHeader,
    KebabMenu,
    CustomDialog
  },
  mixins: [PageContentable, Dossier],
  inject: {
    helper: {
      default: {
        Request
      }
    }
  },
  computed: {
    dossier () {
      return this.value
    }
  },
  methods: {
    ...{ routeFor },
    onDeleted () {
      this.$router.replace({ name: 'dossiers' })
    }
  }
}
</script>
<template>
  <div v-if="value">
    <breadcrumbs :items="dossierBreadcrumbs" />

    <page-detail-header
      state-text="Erstellt"
      :state-updated-at="new Date(value.createdAt)"
      state-color="grey"
      :has-left-sidebar="hasLeftSidebar"
      :has-right-sidebar="hasRightSidebar"
      @open-sidebar="$emit('open-sidebar', $event)"
    >
      <template #actions="{actionRequest, loading}">
        <kebab-menu
          :disabled="loading"
          color="primary"
        >
          <template #items="{ closeMenu }">
            <custom-dialog
              :title="$t('dossier.deleteDialog.title')"
              :text="$t('dossier.deleteDialog.text')"
              :ok-btn-text="$t('dossier.deleteDialog.buttons.ok')"
              ok-btn-color="error"
              @click-ok="actionRequest($apiEndpoints.dossiers.destroy(value.id), onDeleted, helper.Request.DELETE)"
              @open="closeMenu"
            >
              <template #activator="{ on }">
                <v-list-item v-on="on">
                  <v-list-item-title class="error--text">
                    {{ $t('dossier.actions.delete') }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </custom-dialog>
            <v-divider />
            <v-list-item :to="routeFor('dossier_definition', value.definition.id)">
              <v-list-item-title>{{ $t('dossier.actions.editDefinition') }}</v-list-item-title>
            </v-list-item>
          </template>
        </kebab-menu>
      </template>
    </page-detail-header>

    <h1 class="text-h4 font-weight-light mb-4">
      {{ value.title }}
    </h1>
  </div>
</template>
