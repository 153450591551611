import CustomMention from 'lib/tiptap-extensions/custom-mention'
import Request from 'api/request'
import debounce from 'lodash/debounce'
import map from 'lodash/map'

import VMenu from 'vuetify/lib/components/VMenu/VMenu'
import { VList, VListItem, VListItemGroup, VListItemContent } from 'vuetify/lib/components/VList/index'
import VProgressCircular from 'vuetify/lib/components/VProgressCircular/VProgressCircular'

import User from 'mixins/models/user'
import UserListItem from 'list-items/user-list-item.vue'
import GroupListItem from 'list-items/group-list-item.vue'
import WorkflowListItem from 'list-items/workflow-list-item.vue'
import TaskListItem from 'list-items/task-list-item.vue'
import DossierListItem from 'list-items/dossier-list-item.vue'

export default {
  mixins: [User],
  props: {
    groups: {
      type: Array,
      default: () => []
    },
    mentionMenuAttach: {
      type: [Element, Object, String, Boolean],
      default: false
    },
    mentionMenuNudgeBottomDifference: {
      type: Number,
      default: 0
    },
    mentionDisabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      mentionableExtension:
        new CustomMention({
          items: () => [],
          // is called when a suggestion starts
          onEnter: ({ matcherChar, query, range, command, virtualNode }) => {
            if (!this.mentionDisabled) this.mentionableOnSuggestion(true, matcherChar, query, range, command, virtualNode)
          },
          // is called when a suggestion has changed
          onChange: ({ matcherChar, query, range, command, virtualNode }) => {
            if (this.mentionableDebouncedQueryCall) this.mentionableDebouncedQueryCall.cancel()
            this.mentionableDebouncedQueryCall = debounce(() => this.mentionableOnSuggestion(false, matcherChar, query, range, command, virtualNode), 500)
            this.mentionableDebouncedQueryCall()
          },
          // is called when a suggestion is cancelled
          onExit: () => {
            // reset all saved values
            if (this.mentionableDebouncedQueryCall) this.mentionableDebouncedQueryCall.cancel() // prevent opening suggestions from last debounced onChange
            this.mentionableQuery = null
            this.mentionableQueryResult.type = null
            this.mentionableQueryResult.objects = []
            this.mentionableSuggestionRange = null
          },
          // is called on every keyDown event while a suggestion is active
          onKeyDown: ({ event }) => {
            return this.mentionableOnEditorKeyDown(event)
          }
        }),
      mentionableQueryRequest: new Request(),
      mentionableMenuNudgeBottom: 0,
      mentionableMenuWidth: 0,
      mentionableQueryLoading: false,
      mentionableQuery: null,
      mentionableDebouncedQueryCall: null,
      mentionableSuggestionRange: null,
      mentionableQueryResult: {
        type: null,
        objects: []
      },
      mentionableInsertMention: () => {
      },
      mentionableSelectedListItemIndex: 0
    }
  },
  computed: {
    mentionableHasResults () {
      return this.mentionableQueryResult.objects.length > 0
    },
    mentionableShowSuggestions () {
      return this.mentionableQueryLoading || !!this.mentionableQuery || this.mentionableHasResults
    }
  },
  methods: {
    mentionableTypeForMatcherChar (matcherChar) {
      switch (matcherChar) {
        case '@': return 'user_or_group'
        case '%': return 'workflow'
        case '#': return 'task'
        case '*': return 'dossier'
      }
    },
    mentionableEndpointForType (type) {
      switch (type) {
        case 'user_or_group': return this.$apiEndpoints.usersAndGroups.list()
        case 'workflow': return this.$apiEndpoints.workflows.list()
        case 'task': return this.$apiEndpoints.tasks.list()
        case 'dossier': return this.$apiEndpoints.dossiers.list()
      }
    },
    mentionableOnSuggestion (isOnEnter, matcherChar, query, range, command, virtualNode) {
      const rectEditor = this.$refs.tiptapVuetify.$el.getBoundingClientRect()

      if (isOnEnter) this.mentionableMenuWidth = rectEditor.width - 50

      const rectVN = virtualNode.getBoundingClientRect()
      this.mentionableMenuNudgeBottom = rectVN.top + rectVN.height - rectEditor.top + 8
      this.mentionableQueryLoading = true
      this.mentionableSelectedListItemIndex = 0

      this.mentionableQueryResult.type = null
      this.mentionableQueryResult.objects = []
      this.mentionableQuery = null

      const objectType = this.mentionableTypeForMatcherChar(matcherChar)
      this.mentionableQueryRequest.get(this.mentionableEndpointForType(objectType), {
        query,
        group_ids: map(this.groups, 'id')
      }, true)
        .then((data) => {
          this.mentionableQuery = query
          this.mentionableQueryResult.type = objectType
          this.mentionableQueryResult.objects = data
          this.mentionableSuggestionRange = range
          this.mentionableQueryLoading = false
          // we save the command for inserting a selected mention
          // this allows us to call it inside of our custom popup
          // via keyboard navigation and on click
          if (isOnEnter) this.mentionableInsertMention = command
        })
        .catch((error) => {
          this.mentionableQueryLoading = false
          console.log('get data error:')
          console.log(error)
        })
    },
    mentionableOnEditorKeyDown (event) {
      switch (event.keyCode) {
        case 38: // up
          this.mentionableSelectedListItemIndex = (this.mentionableSelectedListItemIndex > 0) ? this.mentionableSelectedListItemIndex - 1 : this.mentionableQueryResult.objects.length - 1
          break
        case 40: // down
          this.mentionableSelectedListItemIndex = (this.mentionableSelectedListItemIndex < this.mentionableQueryResult.objects.length - 1) ? this.mentionableSelectedListItemIndex + 1 : 0
          break
        case 9: // tab
        case 13: // enter
          if (this.mentionableHasResults) {
            this.mentionableSelectObject(this.mentionableQueryResult.objects[this.mentionableSelectedListItemIndex], this.mentionableQueryResult.type)
          }
          break
        case 27: // esc
          this.mentionableResetRequest()
          this.$refs.suggestionMenu.onKeyDown(event)
          break
        default:
          return false
      }

      return true
    },
    // we have to replace our suggestion text with a mention
    // so it's important to pass also the position of your suggestion text
    mentionableSelectObject (object, type) {
      this.mentionableResetRequest()

      this.mentionableInsertMention({
        range: this.mentionableSuggestionRange,
        attrs: {
          mId: object.id,
          mType: type === 'user_or_group' ? object.type : type,
          mNoAccess: false,
          mDeleted: false,
          mLabel: object.mentionLabel
        }
      })
      this.mentionableOnInsert()
    },

    mentionableOnInsert () {
    },

    mentionableResetRequest () {
      this.mentionableQueryRequest.cancel()
      this.mentionableQueryLoading = false
      this.mentionableQuery = null
      if (this.mentionableDebouncedQueryCall) this.mentionableDebouncedQueryCall.cancel()
    },

    genLoadingListItem () {
      return this.$createElement(VListItem, [
        this.$createElement(VListItemContent, {
          class: 'text-center'
        }, [
          this.$createElement(VProgressCircular, {
            props: {
              color: 'primary',
              indeterminate: true
            }
          })
        ])
      ])
    },

    genNoResultListItem () {
      return this.$createElement(VListItem, [
        this.$createElement(VListItemContent, {
          class: 'text-center'
        }, 'Keine Ergebnisse')
      ])
    },

    genResultListItemGroup () {
      return this.$createElement(VListItemGroup, {
        props: {
          value: this.mentionableSelectedListItemIndex,
          mandatory: true
        }
      }, this.mentionableQueryResult.objects.map((object) => {
        let elementComponent
        const customProps = {}
        switch (this.mentionableQueryResult.type) {
          case 'user_or_group':
            if (object.type === 'user') {
              elementComponent = UserListItem
            } else if (object.type === 'group') {
              elementComponent = GroupListItem
              customProps.subtitleElements = (value, defaultResult) => {
                return [`Gruppe mit ${defaultResult[0]}`]
              }
            }
            break
          case 'workflow':
            elementComponent = WorkflowListItem
            break
          case 'task':
            elementComponent = TaskListItem
            break
          case 'dossier':
            elementComponent = DossierListItem
            break
        }

        if (elementComponent) {
          return this.$createElement(elementComponent, {
            props: {
              key: `list-item-${object.id}`,
              value: object,
              indent: true,
              dense: false,
              ...customProps
            },
            on: {
              click: () => this.mentionableSelectObject(object, this.mentionableQueryResult.type)
            }
          })
        } else {
          return null
        }
      }))
    },

    genMentionMenu () {
      return this.$createElement(VMenu, {
        props: {
          value: this.mentionableShowSuggestions,
          attach: this.mentionMenuAttach ? this.mentionMenuAttach : (this.$refs.tiptapVuetify ? this.$refs.tiptapVuetify.$el : null),
          nudgeBottom: this.mentionableMenuNudgeBottom + this.mentionMenuNudgeBottomDifference,
          nudgeRight: 25,
          minWidth: this.mentionableMenuWidth,
          maxWidth: this.mentionableMenuWidth,
          transition: 'slide-y-transition',
          contentClass: 'mention-suggestions-menu'
        },
        ref: 'suggestionMenu'
      }, [
        this.$createElement(VList, {
          class: 'py-0 u-scroll-y',
          props: {
            maxHeight: '400'
          }
        }, [
          this.mentionableQueryLoading
            ? this.genLoadingListItem()
            : (this.mentionableHasResults ? this.genResultListItemGroup() : this.genNoResultListItem())
        ])
      ])
    }
  }
}
