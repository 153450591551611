<script>
import ListItemTemplate from './list-item-template.vue'
import Avatar from 'components/avatar.vue'
import Workflow from 'mixins/models/workflow'
import { distanceDate } from 'helpers/date'
import omit from 'lodash/omit'

export default {
  name: 'WorkflowListItem',
  components: { ListItemTemplate, Avatar },
  mixins: [Workflow],
  props: {
    ...omit(ListItemTemplate.props, ['skeletonType', 'threeLine', 'twoLine', 'disabled']),
    sidebar: Boolean
  },
  computed: {
    workflow () {
      return this.value
    },
    stateUpdatedAtDistance () {
      return distanceDate(this.workflowStateUpdatedAtDate)
    }
  }
}
</script>
<template>
  <list-item-template
    skeleton-type="list-item-three-line"
    v-bind="$props"
    :to="workflowNoAccess ? undefined : to"
    @click="$emit('click', $event)"
  >
    <v-list-item-content>
      <div class="d-flex text-truncate">
        <div class="text-truncate mr-2">
          <v-list-item-subtitle class="text-body-2 mb-2 px-0">
            {{ workflowNoAccess ? 'Bei Bedarf Verantwortliche:n kontaktieren' : value.definition.name }}
          </v-list-item-subtitle>

          <v-list-item-title class="text-subtitle-1 d-flex align-center">
            <v-tooltip
              v-if="workflow.finished && workflowHasAvailableActionFor(workflow, 'complete')"
              top
              max-width="600px"
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  color="blue"
                  v-bind="attrs"
                  class="mr-1"
                  v-on="on"
                >
                  mdi-information
                </v-icon>
              </template>
              <div>{{ $t('workflow.finishedAlert') }}</div>
            </v-tooltip>

            <span :class="{ 'text--disabled': workflowNoAccess }">{{ workflowNoAccess ? 'Prozess - derzeit kein Zugriff' : value.title }}</span>
          </v-list-item-title>
        </div>

        <avatar
          v-if="value.assignee"
          :text="value.assignee.avatar.label"
          :image="value.assignee.avatar.url"
          :tooltip-title="value.assignee.fullname"
          :tooltip-subtitle="value.assignee.email"
          class="ml-auto align-self-start"
          :class="{'mr-2': !sidebar}"
        />
      </div>

      <v-list-item-subtitle class="custom-meta-list">
        <div>{{ workflowIdentifier }}</div>
        <template v-if="!workflowNoAccess">
          <div>
            <span :class="workflowStateColor.text">{{ workflowStateText }}</span>
            <span>{{ stateUpdatedAtDistance }}</span>
          </div>
          <div
            v-if="value.tasksDueCount"
            class="error--text"
          >
            {{ $tc('workflow.tasksDueCount', workflow.tasksDueCount) }}
          </div>
          <div
            v-if="workflow.commentsCount"
            class="d-flex align-center"
          >
            <v-icon
              class="mr-1"
              size="14"
            >
              mdi-comment-outline
            </v-icon>
            <span>{{ sidebar ? workflow.commentsCount : $tc('general.counts.comments', workflow.commentsCount) }}</span>
          </div>
        </template>
      </v-list-item-subtitle>
      <v-list-item-subtitle
        v-if="$scopedSlots.snippets"
        class="mt-6 ml-4"
      >
        <slot name="snippets" />
      </v-list-item-subtitle>
      <v-list-item-subtitle
        v-if="$scopedSlots.references"
        class="mt-6 ml-4"
      >
        <slot name="references" />
      </v-list-item-subtitle>
    </v-list-item-content>
  </list-item-template>
</template>
