import ApiError from './api-error'
import join from 'lodash/join'
import map from 'lodash/map'
import includes from 'lodash/includes'
import without from 'lodash/without'

export default class ValidationError extends ApiError {
  constructor (error) {
    super()

    this.code = error.response.status
    this.errors = error.response.data
  }

  forAttribute (attribute, short = true, asString = true) {
    const attributeError = this.errors[attribute]?.[short ? 'short' : 'full']
    if (!asString || attributeError === undefined) return attributeError

    return join(without(attributeError, null), ', ')
  }

  forNestedAttribute (attribute, short = true) {
    return without(map(this.errors, (value, key) => {
      return (key === attribute || key.startsWith(`${attribute}.`)) ? { [key]: this.forAttribute(key, short) } : null
    }), null)
  }

  forOther () {
    return join(without(this.errors.base?.full, null), ', ')
  }

  forAll (except = [], short = false) {
    return join(without(map(this.errors, (value, key) => {
      return !includes(except, key) ? this.forAttribute(key, short) : null
    }), null), ', ')
  }

  get titleLocalizationKey () {
    return undefined
  }

  get messageLocalizationKey () {
    return undefined
  }
}
