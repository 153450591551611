<script>
export default {
  name: 'InfiniteScrollList',
  props: {
    items: {
      type: Array,
      default: () => undefined
    },
    loadOnNthLastItem: {
      type: Number,
      default: 0
    },
    emptyMessage: {
      type: String,
      default: 'Es gibt keine Ergebnisse.'
    },
    errorMessage: {
      type: String,
      default: 'Etwas ist schief gelaufen...'
    }
  },
  data () {
    return {
      status: 'ok'
    }
  },
  computed: {
    statusOk () {
      return this.status === 'ok'
    },
    statusLoading () {
      return this.status === 'loading'
    },
    statusError () {
      return this.status === 'error'
    },
    statusEmpty () {
      return this.status === 'empty'
    },
    alertType () {
      if (this.statusError) return 'error'
      if (this.statusEmpty) return 'info'

      return undefined
    },
    alertMessage () {
      if (this.statusError) return this.errorMessage
      if (this.statusEmpty && !this.items?.length) {
        return this.emptyMessage
      }

      return undefined
    }
  },
  watch: {
    items: {
      handler (value, oldValue) {
        if (value === undefined) this.load()
      },
      deep: false,
      immediate: true
    }
  },
  methods: {
    load () {
      this.status = 'loading'
      this.$emit('onLoad', (status) => {
        this.status = status
      })
    },
    onIntersect (entries, observer, isIntersecting) {
      if (this.statusOk && entries[0].isIntersecting && Number(entries[0].target.dataset.index) >= (this.items?.length - 1 - this.loadOnNthLastItem)) {
        this.load()
      }
    }
  }
}
</script>
<template>
  <v-list>
    <div
      v-for="(item, index) in items"
      :key="item.key"
      v-intersect.once="onIntersect"
      :data-index="index"
    >
      <slot
        :item="item"
        :index="index"
      />
    </div>

    <v-list-item
      v-if="statusLoading"
      two-line
      class="py-2"
    >
      <v-list-item-content class="text-center">
        <v-progress-circular
          color="primary"
          indeterminate
        />
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-else-if="!statusOk && alertMessage !== undefined">
      <v-list-item-content>
        <v-alert
          :type="alertType"
          text
        >
          {{ alertMessage }}
        </v-alert>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>
