<script>
import Vue from 'vue'
import CustomDialog from 'dialogs/custom-dialog.vue'
import BoolControl from 'controls/bool-control.vue'
import Requestable, { requestablePropFactory } from 'mixins/requestable'
import Request from 'api/request'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'

export default {
  name: 'AddEditBlockDialog',
  components: { CustomDialog, BoolControl },
  mixins: [Requestable],
  props: {
    ...requestablePropFactory().props,
    value: {
      type: Object,
      default: () => {
        return {
          title: '',
          parallel: false,
          contentItemId: null,
          decision: null
        }
      }
    },
    workflow: {
      type: Object,
      required: true
    },
    addMode: Boolean
  },
  data () {
    return {
      dialogOpen: false,
      internalValue: undefined,
      hasDecision: false,
      contentItems: [],
      contentItemsRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            this.contentItems = data
          }
        }
      })
    }
  },
  computed: {
    hasValidChanges () {
      if (!this.internalValue) return false
      if (isEqual(this.internalValue, this.value) && (this.hasDecision === !!this.value.contentItemId)) return false

      let result = this.internalValue.title.trim().length > 0

      if (this.hasDecision) {
        result = result && this.internalValue.contentItemId !== null && this.internalValue.decision !== null
      }

      return result
    },
    requestData () {
      if (!this.internalValue) return {}

      const result = { title: this.internalValue.title, parallel: this.internalValue.parallel }

      if (this.hasDecision) {
        result.content_item_id = this.internalValue.contentItemId
        result.decision = this.internalValue.decision.toString()
      } else {
        result.content_item_id = null
        result.decision = null
      }

      return result
    }
  },
  methods: {
    onDialogOpened () {
      this.internalValue = cloneDeep(this.value)
      this.hasDecision = !!this.internalValue.contentItemId
      this.resetRequestable()

      this.contentItemsRequestable.request(
        { method: Request.GET, url: this.$apiEndpoints.workflows.contentItems(this.workflow.id) },
        { type: 'boolean' }, null, true, true
      )

      this.$emit('open')
    },
    onRequestSuccess (data) {
      this.dialogOpen = false

      if (data) {
        this.$emit('request-success-data', data)
      }
    }
  }
}
</script>
<template>
  <custom-dialog
    v-model="dialogOpen"
    :title="`Block ${addMode ? 'hinzufügen' : 'bearbeiten'}`"
    fullheight
    :close-on-button-click="false"
    :ok-btn-disabled="!hasValidChanges"
    :loading="requestableLoading"
    :error-message="baseErrorMessage"
    @click-ok="request(requestParameter, null, requestData)"
    @click-cancel="dialogOpen = false"
    @open="onDialogOpened"
  >
    <template
      v-if="$scopedSlots.activator"
      #activator="{ on }"
    >
      <slot
        name="activator"
        :on="on"
      />
    </template>
    <template v-if="internalValue">
      <v-text-field
        v-model="internalValue.title"
        label="Name"
        :hint="$t('general.field.required')"
        persistent-hint
        :disabled="requestableLoading"
        :error-messages="validationErrorMessageFor('title')"
        :error-count="Number.MAX_VALUE"
        class="mb-5"
      />

      <v-switch
        v-model="internalValue.parallel"
        label="Alle Aufgaben sollen gleichzeitig aktiv werden"
        :disabled="requestableLoading"
        :error-messages="validationErrorMessageFor('parallel')"
        :error-count="Number.MAX_VALUE"
        class="mb-1"
      />

      <v-switch
        v-model="hasDecision"
        label="Bedingung für die Aktivierung des Blocks festlegen?"
        :disabled="requestableLoading"
        class="mt-1"
      />

      <v-card :disabled="!hasDecision">
        <v-card-text>
          <v-autocomplete
            v-model="internalValue.contentItemId"
            item-value="id"
            :item-text="(item) => `${item.label} [${item.type.label}]`"
            :loading="contentItemsRequestable.requestableLoading"
            label="Welches Datenfeld soll geprüft werden?"
            :hint="$t('general.field.required')"
            persistent-hint
            :disabled="requestableLoading || contentItemsRequestable.requestableLoading"
            :error-messages="validationErrorMessageFor('content_item_id')"
            :error-count="Number.MAX_VALUE"
            :items="contentItems"
            class="mb-5"
          />

          <bool-control
            v-model="internalValue.decision"
            label="Bei welchem Wert soll Block aktiv werden?"
            :disabled="requestableLoading"
            required
            :error-messages="validationErrorMessageFor('decision')"
            :error-count="Number.MAX_VALUE"
          />
        </v-card-text>
      </v-card>
    </template>
  </custom-dialog>
</template>
