<script>
import Vue from 'vue'
import { PageContentable } from '../page'
import Breadcrumbs from 'components/breadcrumbs.vue'
import PageDetailHeader from 'components/page-detail-header.vue'
import KebabMenu from 'components/kebab-menu.vue'
import TextControl from 'controls/text-control.vue'
import SwitchControl from 'controls/switch-control.vue'
import BoolControl from 'controls/bool-control.vue'
import CustomDialog from 'dialogs/custom-dialog.vue'
import DeleteDialog from 'dialogs/delete-dialog.vue'
import ProcessDefinition from 'mixins/models/process-definition'
import Requestable from 'mixins/requestable'
import Request from 'api/request'
import { distanceDate } from 'helpers/date'
import { routeFor } from 'helpers/route'
import filter from 'lodash/filter'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'

export default {
  name: 'ProcessDefinitionEditContentBlock',
  components: {
    Breadcrumbs,
    PageDetailHeader,
    KebabMenu,
    SwitchControl,
    TextControl,
    BoolControl,
    CustomDialog,
    DeleteDialog
  },
  mixins: [PageContentable, ProcessDefinition],
  props: {
    type: {
      type: String,
      required: true
    },
    subId: {
      type: Number,
      default: undefined
    }
  },
  data () {
    return {
      hasConditionSwitchValue: false,
      conditionDialogOpen: false,
      conditionValueInternal: undefined,
      updateRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            this.conditionDialogOpen = false
            this.onUpdated(data)
            this.$refs.conditionSwitchControl.controlShowSuccessMessage()
          }
        }
      })
    }
  },
  computed: {
    processDefinition () {
      return this.value
    },
    blockDefinition () {
      return this.type === 'block-definition' ? this.processDefinitionGetBlockDefinitionWithIdFor(this.processDefinition, this.subId) : null
    },
    decisionContentItemDefinitions () {
      return filter(this.processDefinition.contentItemDefinitions, (value) => value.type.name === 'boolean')
    },
    conditionDialogHasValidChanges () {
      if (!this.conditionValueInternal) return false

      if (isEqual(this.conditionValueInternal, this.blockDefinition.condition)) return false

      if (this.conditionValueInternal.decision == null || this.conditionValueInternal.itemId == null) return null

      return true
    },
    hasCondition () {
      return (this.blockDefinition?.condition?.itemId != null) && (this.blockDefinition?.condition?.decision != null)
    }
  },
  watch: {
    hasCondition: {
      handler (value) {
        this.hasConditionSwitchValue = value
      },
      immediate: true
    }
  },
  methods: {
    ...{ distanceDate },
    updateConditionData (itemId, decision) {
      this.updateRequestable.request(
        {
          method: Request.PATCH,
          url: this.$apiEndpoints.blockDefinitions.update(this.blockDefinition.id)
        },
        null,
        {
          content_item_definition_id: itemId,
          decision
        }
      )
    },
    onUpdated (value) {
      this.$emit('input', value)
    },
    onDeleted (value) {
      this.onUpdated(value)
      this.$router.replace(routeFor('process_definition', value.id))
    },
    onConditionChange (val) {
      if (val) {
        this.conditionDialogOpen = true
      } else {
        this.updateConditionData(null, null)
      }
    },
    onOpenDialog () {
      this.conditionValueInternal = cloneDeep(this.blockDefinition.condition)
    },
    onCancelDialog () {
      this.conditionDialogOpen = false
      this.hasConditionSwitchValue = this.hasCondition
    }
  }
}
</script>
<template>
  <div v-if="blockDefinition">
    <breadcrumbs :items="processDefinitionBlockBreadcrumbs" />

    <page-detail-header
      :has-left-sidebar="hasLeftSidebar"
      :has-right-sidebar="hasRightSidebar"
      @open-sidebar="$emit('open-sidebar', $event)"
    >
      <template #default>
        <div>Aktualisiert {{ distanceDate(blockDefinition.updatedAt) }}</div>
      </template>
      <template #actions="{loading}">
        <kebab-menu
          :disabled="loading || isReadonly"
          color="primary"
        >
          <template #items="{ closeMenu }">
            <delete-dialog
              :title="$t('processDefinition.dialogs.deleteBlock.title')"
              :text="$t('processDefinition.dialogs.deleteBlock.text')"
              :request-parameter="{ method: 'delete', url: $apiEndpoints.blockDefinitions.destroy(blockDefinition.id) }"
              @request-success="onDeleted"
              @open="closeMenu"
            >
              <template #activator="{ on }">
                <v-list-item v-on="on">
                  <v-list-item-title>Block löschen</v-list-item-title>
                </v-list-item>
              </template>
            </delete-dialog>
          </template>
        </kebab-menu>
      </template>
    </page-detail-header>

    <h1 class="text-h4 font-weight-light mb-4">
      Block
    </h1>

    <text-control
      v-model="blockDefinition.title"
      label="Name"
      required
      :request-parameter="{method: 'patch', url: $apiEndpoints.blockDefinitions.update(blockDefinition.id), mapping: 'title'}"
      :readonly="isReadonly"
      class="mb-5"
      @request-success-data="onUpdated"
    />

    <switch-control
      v-model="blockDefinition.parallel"
      label="Alle Aufgaben sollen gleichzeitig aktiv werden"
      :request-parameter="{method: 'patch', url: $apiEndpoints.blockDefinitions.update(blockDefinition.id), mapping: 'parallel'}"
      :readonly="isReadonly"
      class="mb-1"
      @request-success-data="onUpdated"
    />

    <switch-control
      ref="conditionSwitchControl"
      v-model="hasConditionSwitchValue"
      label="Bedingung für die Aktivierung des Blocks festlegen?"
      class="mt-1"
      :loading="updateRequestable.requestableLoading"
      :disabled="updateRequestable.requestableLoading || isReadonly"
      @change="onConditionChange"
    />

    <v-card v-if="hasCondition">
      <v-card-title class="justify-end">
        <v-btn
          color="primary"
          text
          :readonly="isReadonly"
          @click="conditionDialogOpen = true"
        >
          Bearbeiten
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-autocomplete
          v-model="blockDefinition.condition.itemId"
          item-value="id"
          :item-text="(item) => `${item.label} [${item.type.label}]`"
          label="Welches Datenfeld soll geprüft werden?"
          :items="decisionContentItemDefinitions"
          disabled
          class="mb-5"
        />

        <bool-control
          v-model="blockDefinition.condition.decision"
          label="Bei welchem Wert soll Block aktiv werden?"
          disabled
        />
      </v-card-text>
    </v-card>

    <custom-dialog
      v-model="conditionDialogOpen"
      title="Bedingung für die Aktivierung des Blocks"
      :ok-btn-disabled="!conditionDialogHasValidChanges"
      :close-on-button-click="false"
      :loading="updateRequestable.requestableLoading"
      :error-message="updateRequestable.baseErrorMessage"
      @open="onOpenDialog"
      @click-cancel="onCancelDialog"
      @click-ok="updateConditionData(conditionValueInternal.itemId, conditionValueInternal.decision.toString())"
    >
      <template v-if="conditionValueInternal">
        <v-autocomplete
          v-model="conditionValueInternal.itemId"
          item-value="id"
          :item-text="(item) => `${item.label} [${item.type.label}]`"
          label="Welches Datenfeld soll geprüft werden?"
          :hint="$t('general.field.required')"
          persistent-hint
          :items="decisionContentItemDefinitions"
          class="mb-5"
        />

        <bool-control
          v-model="conditionValueInternal.decision"
          label="Bei welchem Wert soll Block aktiv werden?"
          required
        />
      </template>
    </custom-dialog>
  </div>
</template>
