import Vue from 'vue'
import camelCase from 'lodash/camelCase'
import map from 'lodash/map'
import isObject from 'lodash/isObject'
import isNil from 'lodash/isNil'
import find from 'lodash/find'

Vue.mixin({
  beforeCreate () {
    const options = this.$options
    if (options.apiEndpoints) {
      this.$apiEndpoints = options.apiEndpoints
    } else if (options.parent && options.parent.$apiEndpoints) {
      this.$apiEndpoints = options.parent.$apiEndpoints
    }
  }
})

const API_BASE_URL = '/api/'

function actionToObject (action, defaults) {
  const result = {}

  if (isObject(action)) {
    result.action = action.action
    result.path = action.path
  } else {
    result.action = action
  }

  if (isNil(result.path)) {
    const defaultValue = find(defaults, (value) => { return value.action === result.action })
    result.path = defaultValue ? defaultValue.path : result.action
  }

  return result
}
function collections (...actions) {
  return map(actions, function (action) {
    const actionObj = actionToObject(action, [{ action: 'index', path: '' }, { action: 'create', path: '' }])
    return {
      key: camelCase(actionObj.action),
      member: false,
      path: function () { return actionObj.path === '' ? '' : `/${actionObj.path}` }
    }
  })
}

function members (...actions) {
  return map(actions, function (action) {
    const actionObj = actionToObject(action, [{ action: 'show', path: '' }, { action: 'update', path: '' }, { action: 'destroy', path: '' }])
    return {
      key: camelCase(actionObj.action),
      member: true,
      path: function (id) { return actionObj.path === '' ? `/${id}` : `/${id}/${actionObj.path}` }
    }
  })
}

function addAction (actions, actionDef, parentPath) {
  if (actionDef.member) {
    actions[actionDef.key] = function (id) { return parentPath + actionDef.path(id) }
  } else {
    actions[actionDef.key] = function () { return parentPath + actionDef.path() }
  }
}

function resources (path, collectionActions = ['index', 'create'], memberActions = ['show', 'update', 'destroy']) {
  const actions = {}

  collections(...collectionActions).forEach(function (action) {
    addAction(actions, action, API_BASE_URL + path)
  })

  members(...memberActions).forEach(function (action) {
    addAction(actions, action, API_BASE_URL + path)
  })

  return {
    [camelCase(path)]: actions
  }
}

export default {
  ...resources('workflow_definitions',
    ['index', 'list', 'create', 'show_system_process_definition_single_task'],
    ['show', 'update', 'destroy']
  ),
  ...resources('task_definitions',
    ['create'],
    ['update', 'destroy', 'move', 'clone']
  ),
  ...resources('block_definitions',
    ['create'],
    ['update', 'destroy', 'move']
  ),
  ...resources('data_item_definitions',
    ['create'],
    ['update', 'destroy', 'move']
  ),
  ...resources('workflows',
    ['index', 'new', 'create', 'list'],
    ['show', 'update', 'update_assignee', 'update_contributors', 'update_ambitions',
      'cancel', 'complete', 'reopen', 'destroy', 'add_task', 'add_block', 'content_items', 'update_definition']
  ),
  ...resources('tasks',
    ['index', 'list'],
    ['show', 'update', 'update_assignee', 'update_contributors', 'update_marked', 'update_due_date',
      'update_time_tracking_budget', 'snooze', 'start', 'complete', 'skip', 'reopen', 'move', 'clone', 'destroy',
      'test_move_to_process', 'move_to_process', 'create_share', 'complete_share']
  ),
  ...resources('blocks',
    [],
    ['update', 'move', 'destroy']
  ),
  ...resources('data_items',
    ['index', 'create'],
    ['show', 'update', 'move', 'destroy', 'lock', 'unlock']
  ),
  ...resources('content_types',
    ['index'],
    ['show']
  ),
  ...resources('events',
    ['index', 'time_tracking', 'journal', 'create_comment'],
    ['update_comment', 'delete_comment', 'obsolete_info']
  ),
  ...resources('notifications',
    ['index', 'read', 'read_all', 'delivered'],
    ['bookmark', 'unbookmark', 'done']
  ),
  ...resources('search',
    ['fulltext'],
    []
  ),
  ...resources('dossiers',
    ['index', 'create', 'list', 'new'],
    ['show', 'update', 'destroy', 'show_task_references', 'show_dossier_field_references']
  ),
  ...resources('dossier_definitions',
    ['index', 'create', 'list', 'update_reference'],
    ['show', 'update', 'destroy', 'show_references']
  ),
  ...resources('dossier_item_definitions',
    ['create'],
    ['update', 'destroy', 'move']
  ),
  ...resources('users',
    ['index', 'create', 'list', 'info', 'ping'],
    ['show']
  ),
  ...resources('user_settings',
    ['update', 'update_password', 'update_notification_settings'],
    ['show', 'update_email', 'update_admin_status', 'update_active_status', 'resend_confirmation_instructions', 'groups']
  ),
  ...resources('calendar',
    ['create', { action: 'destroy', path: '' }],
    []
  ),
  ...resources('external_api_access',
    ['create', { action: 'destroy', path: '' }],
    []
  ),
  ...resources('groups',
    ['index', 'create', 'list'],
    ['show', 'update', 'destroy']
  ),
  ...resources('users_and_groups',
    ['list'],
    []
  ),
  ...resources('uploaded_files',
    ['create'],
    ['update']
  ),
  ...resources('pages',
    ['index', 'create', 'list'],
    ['show', 'update', 'destroy']),
  ...resources('menu_entries',
    ['index', 'create', 'locations'],
    ['show', 'update', 'destroy']
  ),
  ...resources('main_menu_entries',
    ['index', 'index_filtered', 'create'],
    ['update', 'destroy', 'move']
  ),
  ...resources('dashboard',
    ['processes', 'dossier_definitions', 'everything_in_view', 'my_last_changes'],
    []
  ),
  ...resources('data_transformations',
    ['index', 'create'],
    ['show', 'update', 'destroy', 'apply', 'confirm_all']
  ),
  ...resources('automations',
    ['index', 'create', 'validate_schedule'],
    ['show', 'update', 'destroy']
  ),
  ...resources('share',
    [],
    ['show', 'complete']
  )
}
