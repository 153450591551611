<script>
import Vue from 'vue'
import Request from 'api/request'
import Requestable from 'mixins/requestable'
import CustomDialog from 'dialogs/custom-dialog.vue'
import DossierDefinitionListItem from 'list-items/dossier-definition-list-item.vue'
import DataControl from 'controls/data-control'
import RichtextareaContent from 'components/richtextarea-content.vue'
import { isContentTypeValueEmpty, updateValueForContentType } from 'helpers/content-types'
import debounce from 'lodash/debounce'
import reduce from 'lodash/reduce'
import indexOf from 'lodash/indexOf'

export default {
  name: 'CreateDossierDialog',
  components: {
    CustomDialog,
    DossierDefinitionListItem,
    DataControl,
    RichtextareaContent
  },
  props: {
    definitionId: {
      type: Number,
      default: undefined
    }
  },
  data () {
    return {
      dialogOpen: false,
      addAnother: false,
      successMessage: undefined,
      currentStep: undefined,
      queryText: null,
      queryItems: [],
      selectedDefinition: null,
      queryMaxResults: 10,
      definitionQueryRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (definitions) => {
            this.queryItems = definitions

            if (this.definitionId !== undefined) {
              this.selectedDefinition = definitions[0]
            }
          }
        }
      }),
      createRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            if (this.addAnother) {
              this.successMessage = this.$t('dossier.createDialog.successMessage', { title: data.referenceLabel })
              this.loadNewDossier()
            } else {
              this.dialogOpen = false
              this.$emit('success', data)
            }
          }
        }
      }),
      newDossierRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (dossier) => {
            this.dossier = dossier
          }
        }
      }),
      dossier: null
    }
  },
  computed: {
    title () {
      return this.selectedDefinition?.name || this.$t('dossier.createDialog.title')
    },
    errorMessage () {
      return this.definitionQueryRequestable.baseErrorMessage ||
          this.createRequestable.baseErrorMessage || this.newDossierRequestable.baseErrorMessage
    },
    hintMessage () {
      if (this.queryItems.length < this.queryMaxResults) {
        return null
      } else {
        return 'Mehr Ergebnisse verfügbar. Bitte verfeinern Sie Ihre Suche.'
      }
    },
    dossierIsValid () {
      if (this.dossier) {
        return reduce(this.dossier.dataFields, function (result, field) {
          if (field.definition.required) result &= !isContentTypeValueEmpty(field.definition.type, field.value)
          return result
        }, true)
      }

      return false
    },
    steps () {
      return (this.definitionId === undefined) ? [1, 2] : [2]
    },
    nextStep () {
      const index = indexOf(this.steps, this.currentStep)
      return (index > -1 && (this.steps.length > (index + 1))) ? this.steps[index + 1] : undefined
    },
    prevStep () {
      const index = indexOf(this.steps, this.currentStep)
      return (index > -1 && (index > 0)) ? this.steps[index - 1] : undefined
    }
  },
  methods: {
    onDialogOpened () {
      this.currentStep = undefined

      this.queryText = ''

      if (this.definitionId !== undefined) {
        this.definitionQueryRequestable.request(
          { method: Request.GET, url: this.$apiEndpoints.dossierDefinitions.list() },
          { included_ids: [this.definitionId], filter_by_groups_only: true },
          null, true)

        this.selectedDefinition = { id: this.definitionId }
        this.setStep('initial')
      } else {
        this.setStep('initial')
        this.query()
      }

      this.$emit('open')
    },
    onDialogClosed () {
      this.definitionQueryRequestable.cancelRequestable()
      this.newDossierRequestable.cancelRequestable()
    },
    setStep (div) {
      let nextStep

      switch (div) {
        case 'initial':
          nextStep = this.steps[0] || this.currentStep
          break
        case 'next':
          nextStep = this.nextStep || this.currentStep
          break
        case 'prev':
          nextStep = this.prevStep || this.currentStep
          break
      }

      if (nextStep !== this.currentStep) {
        this.currentStep = nextStep

        this.definitionQueryRequestable.resetRequestable()
        this.createRequestable.resetRequestable()
        this.newDossierRequestable.resetRequestable()

        this.successMessage = undefined

        if (this.currentStep === 1) {
          this.selectedDefinition = null
        } else if (this.currentStep === 2) {
          this.loadNewDossier()
        }
      }
    },
    loadNewDossier () {
      this.newDossierRequestable.request({ method: Request.GET, url: this.$apiEndpoints.dossiers.new() },
        { dossier_definition_id: this.selectedDefinition.id }, null, true)
    },
    selectAndShowDefinitionDetails (definition) {
      this.selectedDefinition = definition

      this.setStep('next')
    },
    query () {
      this.queryItems = []
      this.definitionQueryRequestable.request(
        { method: Request.GET, url: this.$apiEndpoints.dossierDefinitions.list() },
        {
          query: this.queryText,
          limit: this.queryMaxResults,
          filter_by_groups_only: true
        }, null, true)
    },
    queryTextChanged: debounce(function () {
      this.query()
    }, 500),
    onOkButtonClicked (index) {
      this.successMessage = undefined
      this.addAnother = index === 0
      /*
      TODO: We use setTimeout here to wait for the dossier-control to be closed and update the dossier value.
            This is kind of a hack and it would be better to have a better solution
       */
      setTimeout(() => {
        this.createRequestable.request(
          { method: Request.POST, url: this.$apiEndpoints.dossiers.create() },
          null,
          {
            dossier_definition_id: this.selectedDefinition.id,
            field_data: reduce(this.dossier.dataFields, function (result, field) {
              result[field.definition.id] = updateValueForContentType(field.definition.type, field.value)
              return result
            }, {})
          }
        )
      }, 50)
    }
  }
}
</script>
<template>
  <custom-dialog
    v-model="dialogOpen"
    :title="title"
    fullheight
    :close-on-button-click="false"
    :cancel-btn-text="prevStep === undefined ? undefined : $t('general.buttons.back')"
    :ok-btn-text="nextStep === undefined ? $t('general.buttons.save') : null"
    :ok-btn-disabled="!dossierIsValid"
    :additional-ok-btn-texts="[$t('general.buttons.saveAndCreateAnother')]"
    :loading="createRequestable.requestableLoading"
    :error-message="errorMessage"
    :success-message="successMessage"
    content-class="create-dossier-dialog"
    @click-ok="onOkButtonClicked"
    @click-cancel="prevStep === undefined ? dialogOpen = false : setStep('prev')"
    @open="onDialogOpened"
    @close="onDialogClosed"
  >
    <template
      v-if="$scopedSlots.activator"
      #activator="{ on }"
    >
      <slot
        name="activator"
        :on="on"
      />
    </template>
    <v-window
      v-model="currentStep"
      style="overflow: visible;"
    >
      <v-window-item :value="1">
        <div style="position: sticky; top: 0; z-index: 1; background-color: #fff;">
          <v-text-field
            v-model="queryText"
            :error-messages="definitionQueryRequestable.errorMessage"
            :hint="hintMessage"
            autofocus
            clearable
            class="pt-1"
            label="Suche"
            outlined
            persistent-hint
            @input="queryTextChanged"
          />
          <v-divider class="pb-2" />
        </div>
        <v-list class="py-0">
          <template v-if="!definitionQueryRequestable.requestableLoading">
            <template v-if="queryItems.length > 0">
              <v-subheader>Verfügbare Dossiervorlagen</v-subheader>
              <dossier-definition-list-item
                v-for="definition in queryItems"
                :key="definition.id"
                :value="definition"
                indent
                @click="selectAndShowDefinitionDetails"
              />
            </template>
            <v-list-item v-else>
              <v-list-item-content class="text-center">
                Keine Ergebnisse
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item v-else>
            <v-list-item-content class="text-center">
              <v-progress-circular
                color="primary"
                indeterminate
              />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-window-item>
      <v-window-item :value="2">
        <div v-if="selectedDefinition">
          <richtextarea-content :template="selectedDefinition.description" />
          <div class="mt-6">
            <template v-if="!newDossierRequestable.requestableLoading && dossier">
              <data-control
                v-for="(dataField, index) in dossier.dataFields"
                :key="dataField.definition.id"
                v-model="dataField.value"
                :type="dataField.definition.type"
                :label="dataField.definition.name"
                :required="dataField.definition.required"
                :options="dataField.definition.options"
                :error-messages="createRequestable.validationErrorMessageFor(`field_${dataField.definition.id}`)"
                :class="{'mb-5': index < dossier.dataFields.length - 1}"
                child-of-dialog
              />
            </template>
            <div
              v-else
              class="text-center"
            >
              <v-progress-circular
                color="primary"
                indeterminate
              />
            </div>
          </div>
        </div>
      </v-window-item>
    </v-window>
  </custom-dialog>
</template>
