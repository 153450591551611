<script>
import ListItemTemplate from './list-item-template.vue'
import Avatar from 'components/avatar.vue'
import Task from 'mixins/models/task'
import Workflow from 'mixins/models/workflow'
import { distanceDate, formatDateTime } from 'helpers/date'
import { isPast } from 'date-fns'

export default {
  name: 'TaskListItem',
  components: { ListItemTemplate, Avatar },
  mixins: [Task, Workflow],
  props: {
    ...ListItemTemplate.props,
    sidebar: Boolean,
    contentClass: {
      type: String,
      default: ''
    },
    designMode: Boolean,
    itemClass: {
      type: String,
      default: undefined
    },
    hideWorkflowTitle: Boolean,
    hideWorkflowDefinition: Boolean
  },
  computed: {
    task () {
      return this.value
    },
    workflow () {
      return this.task.workflow
    },
    stateUpdatedAtText () {
      return formatDateTime(this.taskStateUpdatedAtDate)
    },
    stateUpdatedAtDistance () {
      return distanceDate(this.taskStateUpdatedAtDate)
    },
    isDue () {
      return isPast(this.taskDueAtDate)
    },
    showDueDate () {
      return this.task.dueAt && (this.workflowIsActive && this.taskIsOpen)
    },
    showSnoozeUntilDate () {
      return this.task.snoozeUntil && (this.workflowIsActive && this.taskIsOpen)
    },
    itemClassString () {
      const classes = ['task-list-item']
      if (this.sidebar) {
        classes.push('pl-1')
      } else if (this.itemClass) {
        classes.push(this.itemClass)
      }
      return classes.join(' ')
    }
  }
}
</script>
<template>
  <list-item-template
    skeleton-type="list-item-avatar-two-line"
    two-line
    v-bind="$props"
    :item-class="itemClassString"
    @click="$emit('click', $event)"
  >
    <v-icon
      v-if="designMode"
      :disabled="disabled"
      color="purple lighten-2"
      class="handle"
    >
      mdi-drag-horizontal
    </v-icon>
    <v-list-item-content :class="[{'mx-2': sidebar}, contentClass]">
      <v-list-item-subtitle
        v-if="!sidebar && !(hideWorkflowDefinition && hideWorkflowTitle)"
        class="text-body-2 mb-2"
      >
        <template v-if="!hideWorkflowDefinition">
          {{ workflow.definition.name }} >
        </template>
        <template v-if="!hideWorkflowTitle">
          {{ workflow.title }}
        </template>
      </v-list-item-subtitle>
      <v-list-item-title class="text-subtitle-1">
        {{ task.name }}
      </v-list-item-title>
      <v-list-item-subtitle class="custom-meta-list">
        <div v-if="sidebar">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <span
                :class="taskStateColorFor(task, workflowIsActive).text"
                v-on="on"
              >{{ taskStateText }}</span>
            </template>
            <span>{{ stateUpdatedAtText }}</span>
          </v-tooltip>
        </div>
        <div v-else>
          <span
            :class="taskStateColorFor(task, workflowIsActive).text"
            class="mr-1"
          >{{ taskStateText }}</span>
          <span>{{ stateUpdatedAtDistance }}</span>
        </div>

        <div
          v-if="task.commentsCount"
          class="d-flex align-center"
        >
          <v-icon
            class="mr-1"
            size="14"
          >
            mdi-comment-outline
          </v-icon>
          <span>{{ sidebar ? task.commentsCount : $tc('general.counts.comments', task.commentsCount) }}</span>
        </div>

        <v-tooltip
          v-if="task.todoCounts"
          bottom
        >
          <template #activator="{ on }">
            <span
              class="d-flex align-center"
              v-on="on"
            >
              <v-icon
                class="mr-1"
                size="14"
              >
                mdi-format-list-checks
              </v-icon>
              <span>{{ $t('task.todoCounts.short', task.todoCounts) }}</span>
            </span>
          </template>
          <span>{{ $t('task.todoCounts.long', task.todoCounts) }}</span>
        </v-tooltip>

        <template v-if="showDueDate">
          <div
            v-if="sidebar && task.dueAt"
            class="d-flex align-center"
          >
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-icon
                  :color="isDue ? 'red' : null"
                  size="14"
                  v-on="on"
                >
                  mdi-alarm
                </v-icon>
              </template>
              <span>{{ taskDueDistanceText }}</span>
            </v-tooltip>
          </div>
          <div
            v-if="!sidebar && task.dueAt"
            class="d-flex align-center"
            :class="{'red--text': isDue}"
          >
            <v-icon
              class="mr-1"
              :color="isDue ? 'red' : null"
              size="14"
            >
              mdi-alarm
            </v-icon>
            <span>{{ taskDueDistanceText }}</span>
          </div>
        </template>

        <template v-if="showSnoozeUntilDate">
          <div
            v-if="sidebar && task.snoozeUntil"
            class="d-flex align-center"
          >
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-icon
                  size="14"
                  v-on="on"
                >
                  mdi-alarm-snooze
                </v-icon>
              </template>
              <span>{{ taskSnoozeDistanceText }}</span>
            </v-tooltip>
          </div>
          <div
            v-if="!sidebar && task.snoozeUntil"
            class="d-flex align-center"
          >
            <v-icon
              class="mr-1"
              size="14"
            >
              mdi-alarm-snooze
            </v-icon>
            <span>{{ taskSnoozeDistanceText }}</span>
          </div>
        </template>

        <div v-if="task.activeShare">
          {{ $t('task.listItem.hasShare') }}
        </div>

        <div v-if="task.marked">
          <v-icon
            size="14"
          >
            mdi-bookmark
          </v-icon>
        </div>
      </v-list-item-subtitle>
      <v-list-item-subtitle
        v-if="$scopedSlots.snippets"
        class="mt-2 ml-4"
      >
        <slot name="snippets" />
      </v-list-item-subtitle>
    </v-list-item-content>

    <avatar
      v-if="task.assignee"
      :text="task.assignee.avatar.label"
      :image="task.assignee.avatar.url"
      :tooltip-title="task.assignee.fullname"
      :tooltip-subtitle="task.assignee.email"
      :class="{'mr-2': !sidebar}"
      list
    />
  </list-item-template>
</template>
