<script>
import { PageContentable } from '../page'
import HeaderEditContent from './edit-content-header.vue'
import Workflow from 'mixins/models/workflow'
import ProcessDefinition from 'mixins/models/process-definition'
import PageDetailHeaderProcess from 'components/page-detail-header-process.vue'
import KebabMenu from 'components/kebab-menu.vue'
import CustomDialog from 'dialogs/custom-dialog.vue'
import ActivityHub from 'components/activity-hub/index.vue'
import MonitorSubscription from 'components/monitor-subscription.vue'
import CreateProcessDefinitionDialog from 'dialogs/create-process-definition-dialog.vue'
import { routeFor } from 'helpers/route'
import globalIdFor from 'helpers/global-id'
import eventBus from 'helpers/event-bus'

export default {
  name: 'ProcessEditContent',
  components: {
    MonitorSubscription,
    HeaderEditContent,
    PageDetailHeaderProcess,
    KebabMenu,
    CustomDialog,
    CreateProcessDefinitionDialog,
    ActivityHub
  },
  mixins: [PageContentable, Workflow, ProcessDefinition],
  props: {
    designMode: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      processTitleEditDialogOpen: false
    }
  },
  computed: {
    workflow () {
      return this.value ? this.value.workflow : null
    },
    gid () {
      return globalIdFor('Workflow', this.pageProps.id)
    }
  },
  methods: {
    ...{ routeFor },
    openEditProcessTitleDialog (closeMenuCb) {
      this.processTitleEditDialogOpen = true
      if (closeMenuCb) closeMenuCb()
    },
    onUpdated (workflow) {
      this.$emit('input', {
        workflow,
        task: null
      })
    },
    onCommentsCountUpdated (count) {
      if (this.workflow) this.workflow.commentsCount = count
    },
    onDefinitionCreated (definition) {
      this.$router.push(routeFor('workflow_definition', definition.id))
    },
    onDefinitionUpdated () {
      eventBus.$emit('quick-message', 'Vorlage erfolgreich aktualisiert')
    },
    onProcessDeleted () {
      this.$router.replace({ name: 'tasks' })
    },
    onMonitorMessage (data) {
      switch (data.action) {
        case 'updated':
          this.$emit('fetchPageData')
          break
        case 'process_structure_updated':
          this.$emit('component:event', 'reload:process')
          break
      }
    }
  }
}
</script>
<template>
  <div>
    <template v-if="value">
      <monitor-subscription
        :key="`monitor-channel-${gid}`"
        :gid="gid"
        @received="onMonitorMessage"
      />

      <monitor-subscription
        :key="`monitor-process-structure-channel-${gid}`"
        :gid="gid"
        channel="MonitorProcessStructureChannel"
        @received="onMonitorMessage"
      />

      <header-edit-content
        :workflow="workflow"
        :title-dialog-open.sync="processTitleEditDialogOpen"
        @update:workflow="onUpdated"
      />

      <page-detail-header-process
        :workflow="workflow"
        :has-right-sidebar="hasRightSidebar"
        :has-left-sidebar="hasLeftSidebar"
        @open-sidebar="$emit('open-sidebar', $event)"
      >
        <template #actions="{actionRequest, loading}">
          <kebab-menu
            :disabled="loading"
            color="primary"
          >
            <template #items="{ closeMenu }">
              <v-list-item
                v-if="!workflow.definition.autoGenerateTitle && !workflowIsSingleTaskFor(workflow)"
                @click="openEditProcessTitleDialog(closeMenu)"
              >
                <v-list-item-content>{{ $t('workflow.actions.editTitle') }}</v-list-item-content>
              </v-list-item>

              <custom-dialog
                v-if="!isSystemProcessDefinitionSingleTask"
                :title="$t('workflow.updateDefinitionDialog.title')"
                :text="$t('workflow.updateDefinitionDialog.text')"
                :ok-btn-text="$t('workflow.updateDefinitionDialog.buttons.ok')"
                ok-btn-color="error"
                @click-ok="actionRequest($apiEndpoints.workflows.updateDefinition(workflow.id), onDefinitionUpdated)"
                @open="closeMenu"
              >
                <template #activator="{ on }">
                  <v-list-item v-on="on">
                    <v-list-item-content>{{ $t('workflow.actions.updateDefinition') }}</v-list-item-content>
                  </v-list-item>
                </template>
              </custom-dialog>

              <v-list-item
                v-if="!isSystemProcessDefinitionSingleTask"
                :to="routeFor('process_definition', workflow.definition.id)"
              >
                <v-list-item-title>{{ $t('workflow.actions.editDefinition') }}</v-list-item-title>
              </v-list-item>

              <create-process-definition-dialog
                :from-workflow="workflow"
                hide-create-another
                @open="closeMenu"
                @success="onDefinitionCreated"
              >
                <template #activator="{ on }">
                  <v-list-item v-on="on">
                    <v-list-item-content>{{ $t('workflow.actions.createDefinition') }}</v-list-item-content>
                  </v-list-item>
                </template>
              </create-process-definition-dialog>

              <custom-dialog
                v-if="workflowHasAvailableActionFor(workflow, 'complete')"
                :title="$t('workflow.cancelDialog.title')"
                :text="$t('workflow.cancelDialog.text')"
                :ok-btn-text="$t('workflow.cancelDialog.buttons.ok')"
                @click-ok="actionRequest($apiEndpoints.workflows.cancel(workflow.id), onUpdated)"
                @open="closeMenu"
              >
                <template #activator="{ on }">
                  <v-list-item
                    v-on="on"
                  >
                    <v-list-item-content>{{ $t('workflow.actions.cancel') }}</v-list-item-content>
                  </v-list-item>
                </template>
              </custom-dialog>

              <custom-dialog
                v-if="!workflowIsSingleTask"
                :title="$t('workflow.deleteDialog.title')"
                :text="$t('workflow.deleteDialog.text')"
                :ok-btn-text="$t('workflow.deleteDialog.buttons.ok')"
                ok-btn-color="error"
                @click-ok="actionRequest($apiEndpoints.workflows.destroy(workflow.id), onProcessDeleted, 'delete')"
                @open="closeMenu"
              >
                <template #activator="{ on }">
                  <v-list-item
                    v-on="on"
                  >
                    <v-list-item-content>{{ $t('workflow.actions.delete') }}</v-list-item-content>
                  </v-list-item>
                </template>
              </custom-dialog>
            </template>
          </kebab-menu>
        </template>
      </page-detail-header-process>
    </template>

    <activity-hub
      v-if="gid"
      ref="activityhub"
      :key="`activity-hub-${gid}`"
      :gid="gid"
      :groups="(workflow || {}).groups"
      class="mt-12"
      @comments-count-updated="onCommentsCountUpdated"
      @loaded="pageDataLoaded"
    />
  </div>
</template>
