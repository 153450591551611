<script>
import { PageContentable } from '../page'
import Requestable from 'mixins/requestable'
import Request from 'api/request'
import SmartList from '../../components/smart-list.vue'
import WorkflowListItem from 'list-items/workflow-list-item.vue'
import { routeFor } from 'helpers/route'

export default {
  name: 'DossierEditTaskReferencesContent',
  components: {
    SmartList,
    WorkflowListItem
  },
  mixins: [PageContentable, Requestable],
  data () {
    return {
      references: undefined
    }
  },
  watch: {
    value: {
      handler () {
        this.references = undefined
        if (this.value) {
          this.request(
            { method: Request.GET, url: this.$apiEndpoints.dossiers.showTaskReferences(this.value.id) },
            null, null, true, true
          )
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...{ routeFor },
    onRequestSuccess (data) {
      this.references = data
    },
    onRequestError (error) {
      console.log('get data error:')
      console.log(error)
    }
  }
}
</script>
<template>
  <smart-list
    v-slot="{ item }"
    :value="references"
    :loading="requestableLoading"
    empty-list-message="Dieses Dossier wird in keinem Prozess genutzt."
  >
    <workflow-list-item
      :value="item.workflow"
      :to="routeFor('task', item.linkTaskId)"
      indent
      :dense="false"
      divider
    >
      <template #references>
        <div
          v-for="field in item.dossierFields"
          :key="field.id"
          class="mb-4"
        >
          <div class="font-weight-medium mb-2">
            {{ field.label }}
          </div>
          <div
            class="d-inline-flex flex-wrap"
            style="gap: 8px;"
          >
            <template v-for="(referencedDossier, indexValue) in field.value">
              <router-link
                v-if="referencedDossier.id !== value.id"
                :key="`${indexValue}__element`"
                :to="routeFor('dossier_task_references', referencedDossier.id)"
                class="blue lighten-5 primary--text object-link object-link--chip mr-2"
              >{{ referencedDossier.title }}</router-link>
              <router-link
                v-else
                :key="`${indexValue}__element`"
                :to="routeFor('dossier_task_references', referencedDossier.id)"
                class="primary--text object-link--chip object-link--outline mr-2"
                style="text-decoration: none;"
              >{{ referencedDossier.title }}</router-link>
            </template>
          </div>
        </div>
      </template>
    </workflow-list-item>
  </smart-list>
</template>
