<script>
import { PageContentable } from '../page'
import ObjectsControl from 'controls/objects-control/index.vue'
import ProcessDefinitionListItem from 'list-items/process-definition-list-item.vue'
import Group from 'mixins/models/group'
import { routeFor } from 'helpers/route'

export default {
  name: 'GroupEditContentProcessDefinitions',
  components: {
    ObjectsControl,
    ProcessDefinitionListItem
  },
  mixins: [PageContentable, Group],
  computed: {
    group () {
      return this.value
    }
  },
  methods: {
    ...{ routeFor },
    onUpdated (value) {
      this.$emit('input', value)
    }
  }
}
</script>
<template>
  <div v-if="value">
    <objects-control
      v-model="value.workflowDefinitions"
      label="Prozessvorlagenauswahl"
      :update-request-parameter="{method: 'patch', url: $apiEndpoints.groups.update(value.id), mapping: 'workflow_definition_ids'}"
      :list-request-parameter="{method: 'get', url: $apiEndpoints.workflowDefinitions.list()}"
      :readonly="!$config.current_user.isAdmin || isReadonly"
      :change-button-tooltip="$config.current_user.isAdmin ? undefined : $t('general.tooltips.onlyAdminsCanChangeSelection')"
      @request-success-data="onUpdated"
    >
      <template #list-item="{item, indent, itemLink, selectable, selected, toggleElement}">
        <process-definition-list-item
          :key="item.id"
          :indent="indent"
          :item-link="itemLink"
          :selectable="selectable"
          :selected="selected"
          :skeleton="item.skeleton"
          :value="item"
          :to="routeFor('process_definition', item.id)"
          @click="toggleElement ? toggleElement(item) : {}"
        />
      </template>
    </objects-control>
  </div>
</template>
