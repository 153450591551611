<script>
import Vue from 'vue'
import Request from 'api/request'
import Requestable from 'mixins/requestable'
import CustomDialog from 'dialogs/custom-dialog.vue'
import FooterMenuEntry from 'mixins/models/footer-menu-entry'
import TextControl from 'controls/text-control.vue'
import SwitchControl from 'controls/switch-control.vue'
import ObjectsControl from 'controls/objects-control/index.vue'
import ObjectListItem from 'list-items/object-list-item.vue'
import NumericControl from 'controls/numeric-control.vue'
import isNil from 'lodash/isNil'

export default {
  name: 'CreateFooterMenuEntryDialog',
  components: {
    CustomDialog,
    TextControl,
    SwitchControl,
    ObjectsControl,
    ObjectListItem,
    NumericControl
  },
  mixins: [FooterMenuEntry],
  data () {
    return {
      dialogOpen: false,
      addAnother: false,
      successMessage: undefined,
      tab: 0,
      locations: null,
      locationsRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (value) => { this.locations = value }
        }
      }),
      createRequestable: new (Vue.extend(Requestable))({
        methods: {
          onRequestSuccess: (data) => {
            if (this.addAnother) {
              this.successMessage = this.$t('footerMenuEntry.createDialog.successMessage', { title: data.title })
              this.initData()
            } else {
              this.dialogOpen = false
              this.$emit('success', data)
            }
          }
        }
      }),
      menuEntry: null
    }
  },
  computed: {
    isValid () {
      if (this.menuEntry) {
        return this.menuEntry.title && this.menuEntry.title.trim().length > 0 &&
            !isNil(this.menuEntry.location) && !isNil(this.menuEntry.position) &&
            (!isNil(this.menuEntry.page) || (this.menuEntry.url && this.menuEntry.url.trim().length > 0))
      }

      return false
    }
  },
  methods: {
    initData () {
      this.menuEntry = {
        title: null,
        location: { value: 'footer_menu', text: null },
        position: null,
        page: null,
        url: null,
        new_window: false
      }
    },
    onDialogOpened () {
      this.initData()

      this.locationsRequestable.resetRequestable()
      this.createRequestable.resetRequestable()

      this.locationsRequestable.request({ method: 'get', url: this.$apiEndpoints.menuEntries.locations() }, null, null, true)

      this.$emit('open')
    },
    onDialogClosed () {
      this.locationsRequestable.cancelRequestable()
    },
    onOkButtonClicked (index) {
      this.successMessage = undefined
      this.addAnother = index === 0

      this.createRequestable.request(
        { method: Request.POST, url: this.$apiEndpoints.menuEntries.create() },
        null,
        {
          title: this.menuEntry.title,
          location: this.menuEntry.location.value,
          position: this.menuEntry.position,
          page_id: this.menuEntry.page ? this.menuEntry.page.id : null,
          url: this.menuEntry.url,
          new_window: this.menuEntry.new_window
        }
      )
    },
    updateTarget ({ page = null, url = null }) {
      this.menuEntry.page = page
      this.menuEntry.url = url
    },
    setLocation (value) {
      this.menuEntry.location.value = value
      this.menuEntry.location.text = null
    }
  }
}
</script>
<template>
  <custom-dialog
    v-model="dialogOpen"
    :title="$t('footerMenuEntry.createDialog.title')"
    fullheight
    :close-on-button-click="false"
    :ok-btn-text="$t('general.buttons.save')"
    :ok-btn-disabled="!isValid"
    :additional-ok-btn-texts="[$t('general.buttons.saveAndCreateAnother')]"
    :loading="createRequestable.requestableLoading"
    :error-message="createRequestable.baseErrorMessage"
    :success-message="successMessage"
    content-class="create-footer-menu-entry-dialog"
    @click-ok="onOkButtonClicked"
    @click-cancel="dialogOpen = false"
    @open="onDialogOpened"
    @close="onDialogClosed"
  >
    <template
      v-if="$scopedSlots.activator"
      #activator="{ on }"
    >
      <slot
        name="activator"
        :on="on"
      />
    </template>
    <template v-if="menuEntry">
      <text-control
        v-model="menuEntry.title"
        label="Titel"
        :hint="$t('general.field.required')"
        persistent-hint
        :disabled="createRequestable.requestableLoading"
        :error-messages="createRequestable.validationErrorMessageFor('title')"
        class="mb-5"
      />

      <!--      <select-control-->
      <!--        :value="menuEntry.location.value"-->
      <!--        :items="locationItemsFor(locations, menuEntry.location)"-->
      <!--        label="Menü"-->
      <!--        :hint="$t('general.field.required')"-->
      <!--        persistent-hint-->
      <!--        :disabled="createRequestable.requestableLoading"-->
      <!--        :error-messages="createRequestable.validationErrorMessageFor('location')"-->
      <!--        class="mb-5"-->
      <!--        @change="setLocation"-->
      <!--      />-->

      <numeric-control
        v-model="menuEntry.position"
        label="Reihenfolge"
        :hint="$t('general.field.required')"
        persistent-hint
        :disabled="createRequestable.requestableLoading"
        :error-messages="createRequestable.validationErrorMessageFor('position')"
        class="mb-5"
      />

      <div class="text-caption">
        Verlinkung
      </div>
      <v-tabs v-model="tab">
        <v-tab>Statische Seite</v-tab>
        <v-tab>Url</v-tab>
      </v-tabs>
      <v-tabs-items
        v-model="tab"
        class="mb-5"
        style="overflow: visible;"
      >
        <v-tab-item>
          <objects-control
            :value="menuEntry.page"
            label="Statische Seite"
            :hint="$t('general.field.required')"
            persistent-hint
            :list-request-parameter="{method: 'get', url: $apiEndpoints.pages.list()}"
            single
            :disabled="createRequestable.requestableLoading"
            :error-messages="createRequestable.validationErrorMessageFor('page')"
            @change="updateTarget({ page: $event })"
          >
            <template #list-item="{item, indent, itemLink, selectable, selected, toggleElement}">
              <object-list-item
                :key="item.id"
                :indent="indent"
                :item-link="itemLink"
                :selectable="selectable"
                :selected="selected"
                :skeleton="item.skeleton"
                :value="{
                  title: item.title,
                  subtitleElements: [item.slug]
                }"
                @click="toggleElement ? toggleElement(item) : {}"
              />
            </template>
          </objects-control>
        </v-tab-item>
        <v-tab-item>
          <text-control
            :value="menuEntry.url"
            :options="{type: 'url'}"
            label="Url"
            :hint="$t('general.field.required')"
            persistent-hint
            :disabled="createRequestable.requestableLoading"
            :error-messages="createRequestable.validationErrorMessageFor('url')"
            @change="updateTarget({ url: $event })"
          />
        </v-tab-item>
      </v-tabs-items>

      <switch-control
        v-model="menuEntry.new_window"
        label="In neuem Fenster öffnen"
        :disabled="createRequestable.requestableLoading"
        :error-messages="createRequestable.validationErrorMessageFor('new_window')"
      />
    </template>
  </custom-dialog>
</template>
