<script>
import { PageContentable } from '../page'
import { ListPageContentable } from '../list-page'
import PageListHeader from 'components/page-list-header.vue'
import CreateDossierDialog from 'dialogs/create-dossier-dialog.vue'
import DataListFilter from 'components/data-list/filter.vue'
import SmartList from 'components/smart-list.vue'
import RichtextareaContent from 'components/richtextarea-content.vue'
import DossierListItem from 'list-items/dossier-list-item.vue'
import { routeFor, exportUrlFor } from 'helpers/route'

export default {
  name: 'DossierListContent',
  components: {
    PageListHeader,
    CreateDossierDialog,
    DataListFilter,
    SmartList,
    DossierListItem,
    RichtextareaContent
  },
  mixins: [PageContentable, ListPageContentable],
  computed: {
    result () {
      return this.value ? this.value.result : undefined
    },
    isSingleDefinitionList () {
      return !!this.pageProps.id
    },
    title () {
      return (this.isSingleDefinitionList ? (this.value?.dossier_definition?.name || '') : 'Dossiers')
    },
    description () {
      return (this.isSingleDefinitionList ? (this.value?.dossier_definition?.description || '') : undefined)
    },
    definitionId () {
      return this.value?.dossier_definition?.id
    }
  },
  methods: {
    ...{ routeFor, exportUrlFor },
    onCreated (dossier) {
      this.$router.push(routeFor('dossier', dossier.id))
    }
  }
}
</script>
<template>
  <div>
    <create-dossier-dialog
      :definition-id="definitionId"
      @success="onCreated"
    >
      <template #activator="{ on }">
        <page-list-header
          :title="title"
          :primary-action-title="isSingleDefinitionList ? $t('general.buttons.create') : $t('dossier.actions.create')"
          v-on="on"
        >
          <template #secondary-items>
            <v-list-item :href="exportUrlFor(pageRequestParameter.url, pageRequestParameter.params)">
              <v-list-item-title>{{ $t('dossier.actions.export') }}</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="isSingleDefinitionList && value && value.dossier_definition"
              :to="routeFor('dossier_definition', value.dossier_definition.id)"
            >
              <v-list-item-title>{{ $t('workflow.actions.editDefinition') }}</v-list-item-title>
            </v-list-item>
          </template>
        </page-list-header>
      </template>
    </create-dossier-dialog>

    <richtextarea-content
      v-if="description"
      :template="description"
      class="mt-2"
    />

    <data-list-filter
      v-model="filters"
      @update:filter="$emit('update:prop', { prop: 'filters', value: $event.value, info: $event.field })"
    />

    <smart-list
      v-slot="{ item }"
      :value="result"
      :loading="pageRequestLoading"
    >
      <dossier-list-item
        :value="item"
        :to="routeFor('dossier', item.id)"
        indent
        :dense="false"
        divider
      />
    </smart-list>

    <v-pagination
      v-if="!pageRequestLoading && result && pages.total() > 1"
      v-model="pages.current"
      :length="pages.total()"
      circle
      class="mt-6"
      @input="$emit('update:prop', { prop: 'pages', value: pages })"
    />
  </div>
</template>
